class HttpConfig {
  //线上环境
  // requestUrl = "http://api.jsytwh.com";
  // editUrl = "http://h5.jsytwh.com";

  //测试环境
  requestUrl = "http://129.211.191.80:19003";
  editUrl = "http://test.admin.dianzedushu.com";
  // editUrl = "http://test.jsytwh.com";

  //本地edit环境
  // editUrl = "http://localhost:8080";
  // editUrl = "http://129.211.191.80:19003";
}

export default new HttpConfig();
