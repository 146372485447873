import Vue from 'vue'
import Vuex from 'vuex'
// 导入持久化插件
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
/* 单元 */
import fullReductionShop from './modules/fullReductionShop.js'
Vue.use(Vuex)
const store = new Vuex.Store({
	modules:{
		fullReductionShop,
	},
	getters,
	plugins:[
		createPersistedState({
			storage: window.sessionStorage //指明存储在sessionStorage中
		})
	]
})

export default store

